import React, { Component } from 'react';
import PostNavigation from './PostNavigation';
import data from '../data/data.json';

class Screen2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: {
                __html: ''
            },
            postPreviousId: null,
            postNextId: null
        };
    }

    async createMarkup(id) {
        const page = await fetch(`/${id}.html`);
        const data = await page.text();

        this.setState({
            page: {
                __html: data
            }
        });
        // return {
        //     __html: '<div><div style="margin-left: 280px;">hello</div></div>'
        // };
    }

    getPreviousNext(postCurrentId) {
        // get a list of all post ids
        const keys = Object.keys(data.blogPosts);
     
        // find position in list of current post id
        let currentPostIndex = null;

        for (let i=0; i<data.blogPosts.length; i++) {
            console.log(data.blogPosts[i].fileName)
            if (postCurrentId == data.blogPosts[i].fileName) {
                currentPostIndex = i;
                break;
            }
        }

        // find previous post id, with a lower constraint
        const postPreviousId =
            currentPostIndex > 0 ? keys[currentPostIndex - 1] : null;
        // find next post id, with upper constraint
        const postNextId =
            currentPostIndex < keys.length ? keys[currentPostIndex + 1] : null;

        // get file name of post id
        const postPreviousFileName = postPreviousId? data.blogPosts[postPreviousId].fileName: null;
        const postNextFileName = postNextId? data.blogPosts[postNextId].fileName: null;

        // set previous and next post file name in state
        this.setState({ postPreviousFileName, postNextFileName });
    }

    componentDidMount() {
        const { postId } = this.props.match.params;

        this.createMarkup(postId);
        this.getPreviousNext(postId);
    }

    render() {
        const { postId } = this.props.match.params;
        return (
            <div>
                <div className="blog-post px-3 py-5 p-md-5">
                    <div className="container single-col-max-width">
                        <div dangerouslySetInnerHTML={this.state.page} />
                        <PostNavigation
                            postCurrent={postId}
                            postPrevious={this.state.postPreviousFileName}
                            postNext={this.state.postNextFileName}
                        ></PostNavigation>
                    </div>
                </div>
            </div>
        );
    }
}

export default Screen2;
