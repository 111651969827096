import React, { Component } from 'react';

const date = new Date();
const year = date.getFullYear();

class Footer extends Component {
  render() {
    return (
      <footer className="footer text-center py-4">
        <small className="copyright">
          Copyright © {year + ' '}
          <a href="https://iwankruger.com/" target="_blank">
            Iwan Kruger
          </a>
        </small>
      </footer>
    );
  }
}

export default Footer;
