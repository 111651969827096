import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import thunk from 'redux-thunk';
import reducers from './reducers';
import Screen1 from './components/Screen1';
import Navigation from './components/Navigation';
import Post from './components/Post';
import Footer from './components/Footer';

const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <div>
                <Switch>
                    <Route path="/posts/new" component={Navigation} />
                    <Route path="/posts/:postId" component={Post} />
                    <Route path="/" component={Screen1} />
                </Switch>
            </div>
        </BrowserRouter>
        <Footer />
    </Provider>,
    document.getElementById('root')
);
